import home from "./home";
import certificationService from "./certification-service";
import customerManagement from "./customer-management";
import orderManagement from "./order-management";
import businessManagement from "./business-management";
import marketService from "./market-service";
import financialStatistics from "./financial-statistics";
import personalCenter from "./personal-center";
import payment from "./payment";

export default [
  ...home,
  ...certificationService,
  ...customerManagement,
  ...orderManagement,
  ...businessManagement,
  ...financialStatistics,
  ...marketService,
  ...personalCenter,
  ...payment,
];
