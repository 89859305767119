export default [
  {
    path: "/orderManagement",
    name: "orderManagement",
    redirect: "/orderManagement/customerOrder/list",
    component: () => import("@views/order-management/index.vue"),
    meta: {
      role: "2",
      pageName: "订单管理",
      iconName: "icon-customer-order-menu",
    },
    children: [
      {
        path: "/orderManagement/customerOrder/list",
        name: "orderManagement.customerOrder.list",
        component: () =>
          import("@views/order-management/customer-order/list.vue"),
        meta: {
          pageName: "客户订单",
        },
      },
      {
        path: "/orderManagement/customerOrder/detail/:orderId",
        name: "orderManagement.customerOrder.detail",
        component: () =>
          import("@views/order-management/customer-order/detail.vue"),
        meta: {
          hideMenu: true,
          pageName: "订单详情",
        },
        props: true,
      },
      {
        path: "/orderManagement/createOrder",
        name: "orderManagement.createOrder",
        redirect: "/orderManagement/createOrder/companyList",
        component: () =>
          import("@views/order-management/create-order/index.vue"),
        meta: {
          hideMenu: true,
          pageName: "代客下单",
        },
        children: [
          {
            path: "/orderManagement/createOrder/companyList",
            name: "orderManagement.createOrder.companyList",
            component: () =>
              import(
                "@/views/order-management/create-order/company-list/index.vue"
              ),
            meta: {
              hideMenu: true,
              pageName: "选择客户",
            },
          },
          {
            path: "/orderManagement/createOrder/applicationCenter",
            name: "orderManagement.createOrder.applicationCenter",
            component: () =>
              import(
                "@/views/order-management/create-order/application-center/index.vue"
              ),
            meta: {
              hideMenu: true,
              pageName: "选择产品",
            },
          },
          {
            path: "/orderManagement/createOrder/productDetail",
            name: "orderManagement.createOrder.productDetail",
            component: () =>
              import(
                "@/views/order-management/create-order/product-detail/index.vue"
              ),
            meta: {
              hideMenu: true,
              pageName: "选择产品",
            },
          },
          {
            path: "/orderManagement/createOrder/confirmOrder",
            name: "orderManagement.createOrder.confirmOrder",
            component: () =>
              import(
                "@/views/order-management/create-order/confirm-order/index.vue"
              ),
            meta: {
              hideMenu: true,
              pageName: "确认订单",
            },
          },
        ],
      },
    ],
  },
];
