export default [
  {
    path: "/customerManagement",
    name: "customerManagement",
    redirect: "/customerManagement/personalCustomer/list",
    component: () => import("@views/customer-management/index.vue"),
    meta: {
      role: "2",
      pageName: "客户管理",
      iconName: "icon-customer-menu",
    },
    children: [
      {
        path: "/customerManagement/personalCustomer/list",
        name: "customerManagement.personalCustomer.list",
        component: () =>
          import("@views/customer-management/personal-customer/list.vue"),
        meta: {
          pageName: "我的客户",
        },
      },
      {
        path: "/customerManagement/personalCustomer/detail",
        name: "customerManagement.personalCustomer.detail",
        component: () =>
          import("@views/customer-management/personal-customer/detail.vue"),
        meta: {
          hideMenu: true,
          pageName: "客户详情",
          defaultActiveMenu: "/customerManagement/personalCustomer",
        },
      },
      {
        path: "/customerManagement/personalService/list",
        name: "customerManagement.personalService.list",
        component: () =>
          import("@views/customer-management/personal-service/list.vue"),
        meta: {
          pageName: "我的服务商",
        },
      },
      {
        path: "/customerManagement/personalService/detail",
        name: "customerManagement.personalService.detail",
        component: () =>
          import("@views/customer-management/personal-service/detail.vue"),
        meta: {
          hideMenu: true,
          pageName: "服务商详情",
          defaultActiveMenu: "/customerManagement/personalService",
        },
      },
    ],
  },
];
