export default [
  {
    path: "/financialStatistics",
    name: "financialStatistics",
    redirect: "/financialStatistics/commissionOverview",
    component: () => import("@views/financial-statistics/index.vue"),
    meta: {
      role: "2",
      pageName: "财务统计",
      iconName: "icon-financial-statistics-menu",
    },
    children: [
      // 返佣总览
      {
        path: "/financialStatistics/commissionOverview",
        name: "financialStatistics.commissionOverview",
        component: () =>
          import("@views/financial-statistics/commission-overview/index.vue"),
        meta: {
          pageName: "返佣总览",
        },
      },
      // 我的账户
      {
        path: "/financialStatistics/personalAccount",
        name: "financialStatistics.personalAccount",
        component: () =>
          import("@views/financial-statistics/personal-account/index.vue"),
        meta: {
          pageName: "我的账户",
        },
      },
      // 返佣流水
      {
        path: "/financialStatistics/commissionFlow",
        name: "financialStatistics.commissionFlow",
        component: () =>
          import("@views/financial-statistics/commission-flow/index.vue"),
        meta: {
          pageName: "返佣流水",
        },
      },
      // 提现管理
      {
        path: "/financialStatistics/withdrawal",
        name: "financialStatistics.withdrawal",
        component: () =>
          import("@views/financial-statistics/withdrawal/index.vue"),
        meta: {
          pageName: "提现管理",
        },
      },
    ],
  },
];
