export default [
  {
    path: "/marketService",
    name: "marketService",
    redirect: "/marketService/applicationCenter/list",
    component: () => import("@views/market-service/index.vue"),
    meta: {
      role: "2",
      pageName: "服务市场",
      iconName: "icon-market-service-menu",
    },
    children: [
      {
        path: "/marketService/applicationCenter/list",
        name: "marketService.applicationCenter.list",
        component: () =>
          import("@views/market-service/application-center/list.vue"),
        meta: {
          pageName: "应用中心",
        },
      },
      {
        path: "/marketService/applicationCenter/detail",
        name: "marketService.applicationCenter.detail",
        component: () =>
          import("@views/market-service/application-center/detail.vue"),
        meta: {
          hideMenu: true,
          pageName: "应用详情",
          defaultActiveMenu: "/marketService",
        },
      },
      {
        path: "/marketService/personalAgent/list",
        name: "marketService.personalAgent/list",
        component: () =>
          import("@views/market-service/personal-agent/list.vue"),
        meta: {
          pageName: "我的代理",
        },
      },
      {
        path: "/marketService/personalOrder/list",
        name: "marketService.personalOrder.list",
        component: () =>
          import("@views/market-service/personal-order/list.vue"),
        meta: {
          pageName: "我的订单",
        },
      },
    ],
  },
];
