export default [
  {
    path: "/businessManagement",
    name: "businessManagement",
    redirect: "/businessManagement/ospreyCloudCall",
    component: () => import("@views/business-management/index.vue"),
    meta: {
      role: "2",
      pageName: "业务管理",
      iconName: "icon-customer-menu",
    },
    children: [
      {
        path: "/businessManagement/ospreyCloudCall",
        name: "businessManagement.ospreyCloudCall",
        redirect: "/businessManagement/ospreyCloudCall/report/list",
        component: () =>
          import("@views/business-management/osprey-cloud-call/index.vue"),
        meta: {
          pageName: "鱼鹰云呼叫",
        },
        children: [
          {
            path: "/businessManagement/ospreyCloudCall/report/list",
            name: "businessManagement.ospreyCloudCall.report.list",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/report/list.vue"
              ),
            meta: {
              pageName: "业务报备",
              moduleTitle: "业务管理",
            },
          },
          {
            path: "/businessManagement/ospreyCloudCall/report/detail",
            name: "businessManagement.ospreyCloudCall.report.detail",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/report/detail.vue"
              ),
            meta: {
              pageName: "报备详情",
            },
          },
          {
            path: "/businessManagement/ospreyCloudCall/business/list",
            name: "businessManagement.ospreyCloudCall.business.list",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/business/list.vue"
              ),
            meta: {
              pageName: "业务管理",
              moduleTitle: "业务管理",
            },
          },
          // 业务管理详情
          {
            path: "/businessManagement/ospreyCloudCall/business/:type/detail",
            name: "businessManagement.ospreyCloudCall.business.detail",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/business/detail.vue"
              ),
            props: true,
            meta: {
              pageName: "详情",
              hideMenu: true,
            },
          },
          {
            path: "/businessManagement/ospreyCloudCall/resource/list",
            name: "businessManagement.ospreyCloudCall.resource.list",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/resource/list.vue"
              ),
            meta: {
              pageName: "资源管理",
              moduleTitle: "业务管理",
            },
          },
          {
            path: "/businessManagement/ospreyCloudCall/callStatistics",
            name: "businessManagement.ospreyCloudCall.callStatistics",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/call-statistics/index.vue"
                ),
            meta: {
              pageName: "通话统计",
              moduleTitle: "呼叫统计",
            },
          },
          {
            path: "/businessManagement/ospreyCloudCall/priceSetting",
            name: "businessManagement.ospreyCloudCall.priceSetting",
            component: () =>
              import(
                "@views/business-management/osprey-cloud-call/price-setting/index.vue"
              ),
            meta: {
              pageName: "公司单价设置",
              moduleTitle: "设置中心",
            },
          },
        ],
      },
      {
        path: "/businessManagement/gatheringGuests",
        name: "businessManagement.gatheringGuests",
        redirect: "/businessManagement/gatheringGuests/priceSetting",
        component: () =>
          import("@views/business-management/gathering-guests/index.vue"),
        meta: {
          pageName: "21秒云集客",
        },
        children: [
          {
            path: "/businessManagement/gatheringGuests/priceSetting",
            name: "businessManagement.gatheringGuests.priceSetting",
            component: () =>
              import(
                "@views/business-management/gathering-guests/price-setting/index.vue"
              ),
            meta: {
              pageName: "公司单价设置",
            },
          },
        ],
      },
    ],
  },
];
