import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import menuRouter from "./menu-router/index";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    meta: {
      pageName: "登录",
      notRequiresAuth: true,
    },
    component: () => import("@views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      pageName: "注册",
      notRequiresAuth: true,
    },
    component: () => import("@views/register.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    meta: {
      pageName: "忘记密码",
      notRequiresAuth: true,
    },
    component: () => import("@views/forget-password.vue"),
  },
  {
    path: "/modifyPassword",
    name: "modifyPassword",
    meta: {
      pageName: "修改密码",
      notRequiresAuth: true,
    },
    component: () => import("@views/modify-password.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("@views/not-found.vue"),
    meta: {
      pageName: "404",
      notRequiresAuth: true,
    },
  },
  {
    path: "/pay-form", // 后端返回支付表单，需要的容器
    name: "payForm",
    component: () => import("@views/pay-form.vue"),
    meta: {
      pageName: "支付容器",
    },
  },
  {
    path: "/mainEntry",
    name: "mainEntry",
    component: () => import("@views/main-entry.vue"),
    children: [...menuRouter],
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
