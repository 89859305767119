export default [
  {
    path: "/personalCenter",
    name: "personal-center",
    component: () => import("@views/personal-center/index.vue"),
    meta: {
      role: "2",
      pageName: "个人中心",
      iconName: "icon-personal-center-menu",
    },
  },
];
