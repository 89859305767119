export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@views/home/index.vue"),
    meta: {
      role: "2",
      pageName: "首页",
      iconName: "icon-home-menu",
    },
  },
];
