import router from "@/router";
import { LocalStorage } from "jz-tool-lib";

let lastEtag: unknown;
let hasUpdate = false;

// 检测前端资源是否有更新
function checkUpdate() {
  return new Promise((resolve, reject) => {
    fetch(`/manifest.json?v=${Date.now()}`, {
      method: "head",
    })
      .then((res) => {
        if (res.status === 200) {
          const etag = res.headers.get("etag");
          hasUpdate = !!(lastEtag && etag !== lastEtag);
          resolve(etag);
        } else {
          reject(null);
        }
      })
      .catch(() => {
        reject(null);
      });
  });
}

// 路由拦截
router.beforeEach(async (to, from, next) => {
  if (to.meta.notRequiresAuth) {
    // 不需要登录验证
    next();
  } else {
    if (!LocalStorage.getItem("bd_authorization")) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }
  try {
    const etag = await checkUpdate();
    if (hasUpdate) {
      window.location.reload();
    } else {
      lastEtag = etag;
    }
  } catch (e) {
    console.log(e);
  }
});
// 系统更新后，出现路由chunk不存在或者错误处理。
router.onError((error) => {
  const pattern = /Loading chunk chunk-(.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    location.reload();
  }
});
