export default [
  {
    path: "/payment/vip-buy/:orderId", // 数企支付 pageId: 1: 创建订单 2：立即支付
    name: "payment-vip-buy",
    component: () => import("@views/payment/vip-buy.vue"),
    props: true,
    meta: {
      pageName: "支付",
    },
  },
  {
    path: "/payment/buy", // 代理商系统支付
    name: "payment-buy",
    component: () => import("@views/payment/buy.vue"),
    meta: {
      pageName: "支付",
    },
  },
  {
    path: "/payment/deposit", // 代理商系统支付
    name: "payment-deposit",
    component: () => import("@views/payment/deposit.vue"),
    meta: {
      pageName: "充值",
    },
  },
];
