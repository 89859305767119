export default [
  {
    path: "/certificationService",
    name: "certificationService",
    component: () => import("@views/certification-service/index.vue"),
    meta: {
      role: "0,1,3",
      pageName: "认证服务商",
      iconName: "icon-certification-menu",
    },
  },
];
